<template>
  <router-view v-slot="{ Component }">
    <transition
      mode="out-in"
      enter-active-class="animate__animated animate__fadeIn animate__faster"
      leave-active-class="animate__animated animate__fadeOut animate__faster"
    >
      <component :is="Component" />
    </transition>
  </router-view>
</template>

<script>
export default {
  data() {
    return {
      color: null,
    };
  },
  mounted() {
    if (sessionStorage.theme === "dark") {
      document.getElementsByTagName("html")[0].setAttribute("class", "dark");
    } else {
      document.getElementsByTagName("html")[0].setAttribute("class", "light");
    }
  },
};
</script>
