import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/Home"),
  },
  {
    path: "/works",
    name: "Works",
    component: () => import("@/views/Works"),
  },
  {
    path: "/blog",
    name: "Blog",
    component: () => import("@/views/Blog"),
  },
  {
    path: "/contact",
    name: "Contact",
    component: () => import("@/views/Contact"),
  },
  {
    path: "/blog/:path",
    name: "Content",
    component: () => import("@/views/Content"),
  },
];

const router = createRouter({
  routes,
  history: createWebHistory(),
});

export default router;
